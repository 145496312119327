.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.button34 {
  background: #5E5DF0;
  border-radius: 999px;
  box-shadow: #5E5DF0 0 10px 20px -10px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;

}



.button-89 {
  --b: 3px;
  /* border thickness */
  --s: .45em;
  /* size of the corner */
  --color: #373B44;

  padding: calc(.5em + var(--s)) calc(.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background:
    conic-gradient(from 90deg at var(--b) var(--b), #0000 90deg, var(--color) 0) var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
  transition: .3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: .6em;
  font-size: 16px;

  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-89:hover,
.button-89:focus-visible {
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: .05em;
}

.button-89:active {
  background: var(--color);
  color: #fff;
}

.button1 {
  cursor: pointer;
  font-weight: 700;
  font-family: Helvetica, "sans-serif";
  transition: all .2s;
  padding: 10px 20px;
  border-radius: 100px;
  background: #B6BBC4;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 15px;
}

.button1:hover {
  background: rgba(49, 48, 77, 0.581)
}

.button1>svg {
  width: 34px;
  margin-left: 10px;
  transition: transform .3s ease-in-out;
}

.button1:hover svg {
  transform: translateX(5px);
}

.button1:active {
  transform: scale(0.95);
}



.spacer {
  width: 6px;
  /* Adjust the width to control the spacing */
}



.flipping-button-list {
  padding: 10px;
  /* Add padding here to create space between buttons */
  /* Add any other styles you need */
}



.wrapper {
  display: inline-flex;
  list-style: none;
  height: 120px;
  width: 100%;
  padding-top: 40px;
  font-family: "Poppins", sans-serif;
  justify-content: center;
}

.wrapper .icon {
  position: relative;
  background: #fff;
  border-radius: 50%;
  margin: 100px;
  padding: 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon .square,
.wrapper .icon .triangle {
  position: absolute;
  width: 20px;
  /* Adjust the size as needed */
  height: 20px;
  /* Adjust the size as needed */
  background-color: #555;
  /* Set the background color */
}

.wrapper .icon .triangle {
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
}



.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #888;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  white-space: nowrap;

}


.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #888;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: #1877F2;
  color: #fff;
}

.wrapper .twitter:hover,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip::before,
.wrapper .twitter:hover .triangle {
  background: #1DA1F2;
  color: #fff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before,
.wrapper .instagram:hover .triangle {
  background: #E4405F;
  color: #fff;
}

.icon span {
  position: relative;
  z-index: 1;
  font-size: 28px;
  color: #555;
  /* Adjust the font size as needed */
}


/* spining button */

.flip-container {
  perspective: 1000;
  transform-style: preserve-3d;
  margin: 15% auto;
}

.flip-container.rotate .back {
  transform: rotateY(0deg);
}

.flip-container.rotate .front {
  transform: rotateY(180deg);
}

.flip-container,
.front,
.back {
  width: 4em;
  height: 4em;
}

.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.front,
.back {
  backface-visibility: hidden;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
}

.front {
  z-index: 2;
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
  transform: rotateY(-180deg);
}

.vertical.flip-container {
  position: relative;
}

.vertical .back {
  transform: rotateX(180deg);
}

.vertical.flip-container.rotate .back {
  transform: rotateX(0deg);
}

.vertical.flip-container.rotate .front {
  transform: rotateX(180deg);
}

/* Spinner from http://www.materialup.com/posts/material-design-spinner-css */
.large {
  margin-top: 4em;
  transform: scale(1.5)
}

.spinner-wrapper {
  position: relative;
  width: 4em;
  height: 4em;
  border-radius: 100%;
  left: calc(50% - 2em);
}

.spinner-wrapper::after {
  content: "";
  background: #2196F3;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}

.rotator {
  position: relative;
  width: 4em;
  border-radius: 4em;
  overflow: hidden;
  animation: rotate 2000ms infinite linear;
}

.rotator:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  border: 3px solid #2196F3;
  border-radius: 100%;
}

.inner-spin {
  background: #2196F3;
  height: 4em;
  width: 2em;
}

.inner-spin {
  animation: rotate-left 2500ms infinite cubic-bezier(0.445, 0.050, 0.550, 0.950);
  border-radius: 2em 0 0 2em;
  transform-origin: 2em 2em;
}

.inner-spin:last-child {
  animation: rotate-right 2500ms infinite cubic-bezier(0.445, 0.050, 0.550, 0.950);
  margin-top: -4em;
  border-radius: 0 2em 2em 0;
  float: right;
  transform-origin: 0 50%;
}

@keyframes rotate-left {

  60%,
  75%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-right {

  0%,
  25%,
  45% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}


.clock {
  align-items: center;
  height: 2vh;
  color: black;
  font-size: 2vh;
  font-family: sans-serif;
  line-height: 1.8vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

.clock::before,
.clock::after {
  content: '';
  width: 0.8ch;
  height: 0.4vh;
  background: linear-gradient(to top, transparent, white);
  position: absolute;
  z-index: 2;
}

.clock::after {
  bottom: 0;
  background: linear-gradient(to bottom, transparent, white);
}

.clock>div {
  display: flex;
}

.tick {
  line-height: 1.2vh;
}

.tick-hidden {
  opacity: 0;
}

.move {
  animation: move linear 1s infinite;
}

@keyframes move {
  from {
    transform: translateY(0vh);
  }

  to {
    transform: translateY(-2vh);
  }
}


.seconds {
  margin-right: 5px;
  /* Add some margin between time sections */
}


/* end spining button*/